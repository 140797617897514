import * as React from "react";

const VisiteVirtuelleCrash = () => {
  return (
    <iframe 
      src="https://www.google.com/maps/embed?pb=!4v1711346733073!6m8!1m7!1sCAoSLEFGMVFpcE0 4N2VEUlNZTkpuTTB3Tjg0d2EzOE1wVU9DSUE2Z05oOVVPOEhE!2m2!1d48.8699123!2d2.3188313 !3f62.4713304177418!4f0.5990974952768653!5f0.7820865974627469" 
      width="600" 
      height="450" 
        style={{ width: "100vw", height: "calc(100vh - 50px)" }}
      loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade"
      title="Visite virtuelle de GHOST GALERIE"
      frameborder="0"
      allowfullscreen="true"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
      allowvr="true"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; vr"
    ></iframe>
    // <iframe
    //   title="Visite virtuelle de GHOST GALERIE"
   
    //   src="https://www.klapty.com/tour/tunnel/YQV0v9USAH"
    //   frameborder="0"
    //   allowfullscreen="true"
    //   mozallowfullscreen="true"
    //   webkitallowfullscreen="true"
    //   allowvr="true"
    //   allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; vr"
    // ></iframe>
  );
};

export default VisiteVirtuelleCrash;
